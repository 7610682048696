import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import CharacterDirectoryMainPage from "./character_directory/CharacterDirectoryMainPage";
import CharacterDirectoryUserPage from "./character_directory/CharacterDirectoryUserPage";
import CharacterDirectoryImageDisplayPage from "./character_directory/CharacterDirectoryImageDisplayPage";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<div className="App">
				<header className="App-header">
					<ul style={{ listStyle: "outside" }}>
						<li>
							<Link to={`character_directory`}>
								MapleStory Character Directory
							</Link>
						</li>
					</ul>
				</header>
			</div>
		),
	},
	{
		path: "character_directory",
		element: <CharacterDirectoryMainPage />,
	},
	{
		path: "character_directory/u/:username",
		element: <CharacterDirectoryUserPage />,
	},
	{
		path: "character_directory/u/:username/:year/:month",
		element: <CharacterDirectoryImageDisplayPage />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
